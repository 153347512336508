import React, { Fragment } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import { convertLangCodeForDB } from '@lib';
import category from '@public/categoryIcon.json';
import categories from '@public/category.json';
import useSettings from '@hooks/useSettings';
import mainCardList from '@public/mainCardList.json';
import { Contents, Layout } from '@components/layout';
import { GET_CATEGORY_BANNER_INFO, GET_CATEGORY_CHILD_LIST, GET_CATEGORY_FILTER, GET_CATEGORY_LIST, GET_CATEGORY_TYPE, GET_GODO_BANNER_INFO } from '@api';
import { MainWidget } from '@components/main';
import { CardPromotionGroupOld, Category, PromotionBanner } from '@components/common';
import Page from '@components/Page';
import { PATH_CATEGORY } from 'src/routes/paths';
import CategoryListOld from 'src/section/category/CategoryListOld';
import {
  GetCategoryQueryVariables,
  GetExchangeRateDocument,
  GetExchangeRateQueryVariables,
  MainProductListV2Document,
  MainProductListV2QueryVariables,
  ProductCardsWithBrandDocument,
  ProductCardsWithBrandQueryVariables,
  QueryGetGodoBannerInfoArgs,
  QueryGetTransGodoCategoryChildItemsArgs,
  QueryGetTransGodoCategoryItemsArgs,
  QuerySyncCategoryArgs,
} from 'src/generated/graphql';
import { captureException, ExtraError } from '@util/sentry';
import { serverSideTranslations } from '@util/i18nUtils';
import { catchableQueryFactory, initializeApollo } from '@lib/apolloClient';
import { ResponsiveSlideBanner } from '@components/common/ResponsiveSlideBanner/ResponsiveSlideBanner';
import { IResponsiveSno } from '@components/main/MainWidget/MainWidget';
import { CustomText } from '@components/ui';
import {
  BANNER_INTERNAL_NAME,
  CATEGORY_CODE,
  GOODS_INTERNAL_NAME,
  mainTopSlideCodeMobile,
  mainTopSlideCodePc,
  mainTopSlideCodeTablet,
  MAIN_CATEGORY,
  PAGE_NAME,
  defaultNamespaces,
  CATEGORY_SNO,
} from '../../src/constants';

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>;
};

export default function Home() {
  // 번역 도구
  const { t, i18n } = useTranslation(['new', 'game']);
  const { themeMode } = useSettings();
  const router = useRouter();

  const handleCategoryClick = (cateCd: string) => {
    const target = PATH_CATEGORY.view(cateCd);
    if (router.pathname !== target) router.push(target, null, { scroll: true });
  };

  return (
    <Page isMobileView>
      {themeMode === 'toon' && (
        <Fragment>
          <Box sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
            <ResponsiveSlideBanner bannerCode={mainTopSlideCodePc[i18n.language]} isBorder={false} skeletonHeight={400} />
          </Box>
          <Box sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'none' } }}>
            <ResponsiveSlideBanner bannerCode={mainTopSlideCodeTablet[i18n.language]} isBorder={false} skeletonHeight={240} />
          </Box>
          <Box sx={{ display: { lg: 'none', md: 'none', sm: 'none', xs: 'block' } }}>
            <ResponsiveSlideBanner bannerCode={mainTopSlideCodeMobile[i18n.language]} isBorder={false} skeletonHeight={340} />
          </Box>
        </Fragment>
      )}

      {themeMode === 'game' && (
        <>
          <PromotionBanner code={CATEGORY_CODE.REALISTIC} />
          <CardPromotionGroupOld
            sno={CATEGORY_SNO.GAME}
            subTitle={t('new:subTitle')}
            subTitleColor="#33ee99"
            subTitleTextColor="#313135"
            titleTextColor="#ffffff"
            bgColor="#18181b"
            eventPageName="new"
          />
        </>
      )}

      {themeMode === 'toon' && <MainWidget />}
      {themeMode === 'game' && (
        <>
          <Contents marginTop={0}>
            <CustomText weight={800} size={24} lineHeight={'36px'} color={'#313135'}>
              {t('game:allProducts')}
            </CustomText>
            <Category type={MAIN_CATEGORY.REALISTIC} categoryCode={CATEGORY_CODE.REALISTIC} onClickCategory={handleCategoryClick} />
            <CategoryListOld pageType={PAGE_NAME.GAME} categoryCode={CATEGORY_CODE.REALISTIC} isFilter={true} isBanner={false} isFreeSort={false} />
          </Contents>
        </>
      )}
    </Page>
  );
}

export const getStaticProps = async ({ locale }) => {
  const apolloClient = initializeApollo();
  const catchableQuery = catchableQueryFactory(apolloClient);
  let cachedData = {};
  if (locale && locale !== 'acon') {
    try {
      const [typeData, ...promiseResults] = await Promise.all([
        // 게임 - 카테고리 대분류 정보
        catchableQuery<GetCategoryQueryVariables>(
          {
            query: GET_CATEGORY_TYPE,
            variables: {
              godoCateCd: CATEGORY_CODE.REALISTIC,
            },
          },
          true,
        ),
        // 최상단 슬라이드 배너에 대한 호출
        catchableQuery<QueryGetGodoBannerInfoArgs>(
          {
            query: GET_GODO_BANNER_INFO,
            variables: {
              bannerCode: mainTopSlideCodePc[locale],
            },
          },
          true,
        ),
        catchableQuery<QueryGetGodoBannerInfoArgs>(
          {
            query: GET_GODO_BANNER_INFO,
            variables: {
              bannerCode: mainTopSlideCodeTablet[locale],
            },
          },
          true,
        ),
        catchableQuery<QueryGetGodoBannerInfoArgs>(
          {
            query: GET_GODO_BANNER_INFO,
            variables: {
              bannerCode: mainTopSlideCodeMobile[locale],
            },
          },
          true,
        ),
        catchableQuery<QueryGetTransGodoCategoryChildItemsArgs>(
          {
            query: GET_CATEGORY_CHILD_LIST,
            variables: {
              lang: convertLangCodeForDB(locale),
              cateCds: categories.rootCategory,
              // 현재는 cartoon 대분류만 사용 중.
              type: MAIN_CATEGORY.CARTOON,
            },
          },
          true,
        ),
        // 게임 - 상단 이미지 배너
        catchableQuery<GetCategoryQueryVariables>(
          {
            query: GET_CATEGORY_BANNER_INFO,
            variables: {
              godoCateCd: CATEGORY_CODE.REALISTIC,
            },
          },
          true,
        ),
        // 중간 카테고리 아이콘 그룹
        catchableQuery<QueryGetTransGodoCategoryItemsArgs>(
          {
            query: GET_CATEGORY_LIST,
            variables: {
              cateCds: category[locale],
              lang: convertLangCodeForDB(locale),
            },
          },
          true,
        ),
        // 환율 관련 정보 prefetch
        locale === 'ja' || locale === 'zh'
          ? catchableQuery<GetExchangeRateQueryVariables>(
            {
              query: GetExchangeRateDocument,
              variables: {
                languageCode: locale,
              },
            },
            true,
          )
          : true,
      ]);
      for (let result of promiseResults) {
        if (result instanceof Error) {
          const error: ExtraError = result;
          error.message = 'Handled Error from getStaticProps\nIn promiseAll\n' + error.message;
          captureException(error, { extra: { route: '/' } });
        }
      }

      const type = typeData.data?.getCategory?.type;

      // 중간 배너에 대한 호출
      const promiseArr = [];
      for (const cardInfo of mainCardList[locale] ?? []) {
        switch (cardInfo && cardInfo.type ? cardInfo.type : null) {
          case GOODS_INTERNAL_NAME:
            /* eslint-disable no-case-declarations */
            await catchableQuery<MainProductListV2QueryVariables>({
              query: MainProductListV2Document,
              variables: {
                lang: convertLangCodeForDB(locale),
                sno: cardInfo.sno,
              },
            })
              .then((mainProductsData) => {
                const mainProducts = mainProductsData.data?.mainProductListV2 || null;
                if (mainProducts && mainProducts?.productList && mainProducts.productList.length > 0) {
                  promiseArr.push(
                    catchableQuery<ProductCardsWithBrandQueryVariables>({
                      query: ProductCardsWithBrandDocument,
                      variables: {
                        languageCode: locale,
                        productNos: mainProducts.productList,
                      },
                    }),
                  );
                }
              })
              .catch((error: ExtraError) => {
                error.message = 'Handled Error from getStaticProps\nIn `MainProductListV2Document`, unable to query `ProductCardsWithBrandDocument`\n' + error.message;
                captureException(error, { extra: { route: '/' } });
              });
            break;
          case BANNER_INTERNAL_NAME: {
            const isNoResponsive = Boolean(typeof cardInfo.sno === 'number');
            if (isNoResponsive) {
              promiseArr.push(
                catchableQuery<QueryGetGodoBannerInfoArgs>({
                  query: GET_GODO_BANNER_INFO,
                  variables: {
                    bannerCode: cardInfo.sno,
                  },
                }),
              );
            } else {
              const { mobile, tablet, desktop } = cardInfo.sno as IResponsiveSno;
              promiseArr.push(
                catchableQuery<QueryGetGodoBannerInfoArgs>({
                  query: GET_GODO_BANNER_INFO,
                  variables: {
                    bannerCode: desktop,
                  },
                }),
              );
              promiseArr.push(
                catchableQuery<QueryGetGodoBannerInfoArgs>({
                  query: GET_GODO_BANNER_INFO,
                  variables: {
                    bannerCode: tablet,
                  },
                }),
              );
              promiseArr.push(
                catchableQuery<QueryGetGodoBannerInfoArgs>({
                  query: GET_GODO_BANNER_INFO,
                  variables: {
                    bannerCode: mobile,
                  },
                }),
              );
            }
            break;
          }
        }
      }

      // 게임 - 카테고리 정보
      promiseArr.push(
        catchableQuery<QuerySyncCategoryArgs>({
          query: GET_CATEGORY_FILTER,
          variables: {
            lang: locale,
            type: type,
          },
        }),
      );

      await Promise.all(promiseArr);
      cachedData = apolloClient.cache.extract();
    } catch (error) {
      error.route = '/';
      captureException(error, { extra: { route: '/' } });
    }
  }

  return {
    props: {
      initialApolloState: cachedData,
      ...(await serverSideTranslations(locale === 'acon' ? 'en' : locale, [
        ...defaultNamespaces,
        'event',
        'product',
        'brand',
        'mainCategory',
        'category',
        'productItems',
        'category',
        'search',
        'new',
        'game',
      ])),
    },
    revalidate: 60,
  };
};
